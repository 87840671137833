import PropTypes from 'prop-types';

import { HTML } from '../../components/utils';

import * as styles from './intro.module.scss';

const Intro = ({ body }) => {
	return (
		<div className={styles.wrapper}>
			<HTML content={body} />
		</div>
	);
};

Intro.defaultProps = {
	title: null,
	image: null,
};

Intro.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
};

export default Intro;
