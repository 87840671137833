import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import Blocks from './index/blocks';
import Intro from './shared/intro';

// import * as styles from './index.module.scss';

const Index = ({
	data: {
		markdownRemark: {
			frontmatter: { blocks },
			fields: { slug },
			html
		},
	},
	data
}) => {
	console.log(data)
	return (
		<Page slug={slug} teaser={<Intro body={html} />} isFullWidth={true} isTitleHidden={true} isBackgroundHidden={true}>
			<Blocks items={blocks} />
		</Page>
	);
}

export const indexQuery = graphql`
	query pagesIndexQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			frontmatter {
				blocks {
					header
					text
					date
					image {
						fixed: childImageSharp {
							gatsbyImageData(
								width: 450
								height: 450
								layout: FIXED
								transformOptions: { cropFocus: ENTROPY }
							)
						}
						fluid: childImageSharp {
							gatsbyImageData(width: 768, layout: CONSTRAINED)
						}
					}
					icons {
						icon {
							publicURL
						}
						text
						link
					}
				}
			}
			fields {
				slug
			}
			html
		}
	}
`;

export default Index;
