import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { SVGIcon } from '../../components/icons';

import * as styles from './icons.module.scss';

//
// ICON
//
export const Icon = ({ icon: { publicURL: src }, text }) => (
	<span className={!text ? `${styles.icon}` : `${styles.icon} ${styles.iconHasText}`}>
		<SVGIcon path={src} className={styles.svg} />
		{text && <span className={styles.text}>{text}</span>}
	</span>
);

Icon.defaultProps = {
	icon: null,
	text: null,
};

Icon.propTypes = {
	icon: PropTypes.object.isRequired,
	text: PropTypes.string,
};

//
// ICONS
//
const Icons = ({ items }) => {
	if (!Array.isArray(items)) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			{items.map(({ icon, text, link }, index) => {
				if (typeof link === 'string') {
					const isExternal = link.match(/^https?:\/\//);

					return isExternal ? (
						<a href={link} key={index} target="_blank" className={styles.link}>
							<Icon icon={icon} text={text} />
						</a>
					) : (
						<Link to={link} key={index} className={styles.link}>
							<Icon icon={icon} text={text} />
						</Link>
					);
				}

				return <Icon key={index} icon={icon} text={text} />;
			})}
		</div>
	);
};

Icons.defaultProps = {
	items: null,
};

Icons.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape(Icon.propTypes)),
};

export default Icons;
